import { formatMoney } from '../helpers/money-formatter.js'
import { isEmpty, isFunction, every, uniq, each, map } from 'lodash'

/**
 * This class is responsible for rendering the bulk order form
 * 
 * - renders the form
 * - calls a atc callback to get additional properties
 * - handles the add to cart process
 * 
 * It makes no sense if different customizations are assigned to the variants.
 * 
 * Todos:
 * - handle option filtering
 * - limit number of variants shown
 * - add css
 */
export default class BulkOrderForm extends HTMLElement {
    static SHOW_MORE_INCREASE = 10
    static MAX_RENDERED_VARINATS = 10

    constructor(fpdInstance, wrapper, shopifyProduct, atcCallback) {
      super()

      this.fpdInstance = fpdInstance
      this.wrapper = wrapper
      this.shopifyProduct = shopifyProduct
      this.selectedOptions = map(shopifyProduct?.options, () => "")
      this.atcCallback = atcCallback
      this.additionalProperties = {}
      this.container = null
      this.maxRenderedVariants = BulkOrderForm.MAX_RENDERED_VARINATS
    }

    initBulkOrderForm() {
      this.container = document.createElement("fpd-bulk-order-form")
      this.wrapper.append(this.container)
      this.renderBulkOrderForm()
      this.renderSummeryFooter()
      this.initAtcListener()
    }

    renderBulkOrderForm() {
      this.bulkOrderTable = this.container.querySelector("#fpd-bo-bulk-order-table")
      this.bulkOrderTable.innerHTML = this.renderHeaderSelection()
      this.selectedVariants().forEach((variant, index) => {
        if(index > this.maxRenderedVariants) return
        this.bulkOrderTable.innerHTML += this.renderVariantRow(variant, index)
      })
      this.bulkOrderTable.innerHTML += this.renderShowMoreButton()
      this.initFormEventListeners()
    }

    renderHeaderSelection() {
      const optionValues = {}
      this.shopifyProduct.options.forEach((option, index) => {
        optionValues[option] = uniq(map(this.shopifyProduct.variants, (variant) => {
          return variant.options[index]
        }))
      })
      // console.debug("optionValues", optionValues)
      let headerContent = ""
      each(optionValues, (values, name) => {
        const index = this.shopifyProduct.options.indexOf(name)
        console.log("optionValues", index)
        headerContent += `<select class="bulk-order-header" name="${name}">`
        headerContent += `<option value="" ${this.selectedOptions[index] == "" ? 'selected' : ''}>${name}</option>`
        headerContent += map(values, (value) => (`
          <option ${this.selectedOptions[index] == value ? 'selected' : ''} value="${value}">${value}</option>
        `))
        headerContent += `</select>`
        headerContent += `<span class="fpd-bo-svg">${BulkOrderForm.angleDownSvg}</span>`
      })

      return `
        <div class="fpd-bo-bulk-order-header">
          ${headerContent}
        </div>
      `
    }

    selectedVariants() {
      return this.shopifyProduct.variants.filter((variant) => {
        return every(variant.options, (option, index) => {
          return isEmpty(this.selectedOptions[index]) || this.selectedOptions[index] == option
        })
      })
    }    

    renderVariantRow(variant, index) {
      const variant_image = variant.featured_media || this.shopifyProduct.media[0]
        let variant_image_markup = ""
        if(variant_image) {
          const variant_image_alt = variant_image.alt || `${variant.title}-image`
          let variant_image_src = variant_image.preview_image?.src || variant_image.src
          if(variant_image_src.includes("?")) {
            variant_image_src += "&width=150&height=150"
          } else {
            variant_image_src += "?width=150&height=150"
          }
          variant_image_markup = `<img src="${variant_image_src}" alt="${variant_image_alt}" />`
        }
        return `
          <div class="fpd-bo-bulk-order-row ${index == 0 ? " first-row" : ""}">
            <div class="fpd-bo-name-container">
              <div class="fpd-bo-variant-image">${variant_image_markup}</div>
              <div class="fpd-bo-variant-info">
                <div class="fpd-bo-variant-title">${variant.title}</div>
                <div class="fpd-bo-variant-price">${formatMoney(variant.price, FPD.money_format)}</div>
              </div>
            </div>
            <div class="fpd-bo-quantity-container">
              <div class="fpd-bo-variant-decrease" data-id="${variant.id}">-</div>
              <input type="text" name="${variant.id}" class="fpd-bo-variant-quantity" value="0" />
              <div class="fpd-bo-variant-increase" data-id="${variant.id}">+</div>
            </div>
          </div>
        `
    }

    initFormEventListeners() {
      this.container.querySelectorAll(".fpd-bo-variant-increase").forEach((increase) => {
        increase.addEventListener("click", (e) => {
          const inputElement = this.container.querySelector(`input[name="${e.target.dataset.id}"]`)
          this.changeInputQuantity(inputElement, 1)
          this.renderSummeryFooter()
        })
      })

      this.container.querySelectorAll(".fpd-bo-variant-decrease").forEach((decrease) => {
        decrease.addEventListener("click", (e) => {
          const inputElement = this.container.querySelector(`input[name="${e.target.dataset.id}"]`)
          this.changeInputQuantity(inputElement, -1)
          this.renderSummeryFooter()
        })
      })

      this.container.querySelectorAll(".bulk-order-header").forEach((select, index) => {
        select.addEventListener("change", (e) => {
          this.selectedOptions[index] = e.target.value
          this.renderBulkOrderForm()
        })
      })

      this.container.querySelector(".fpd-bo-show-more-button")?.addEventListener("click", (e) => {
        this.maxRenderedVariants += BulkOrderForm.SHOW_MORE_INCREASE
        this.renderBulkOrderForm()
      })
    }

    changeInputQuantity(element, change) {
      element.value = Math.min(parseInt(element.value) + change, 0)
    }

    renderShowMoreButton() {
      if(this.selectedVariants().length <= this.maxRenderedVariants) return ""

      return `
        <div class="fpd-bo-show-more">
          <button class="fpd-bo-show-more-button">Show More</button>
        </div>
      `
    }

    renderSummeryFooter() {
      this.bulkOrderFooter = document.querySelector("#fpd-bo-bulk-order-footer")
      let totalPriceCent = 0
      let totalQuantity = 0
      this.container.querySelectorAll("form input.fpd-bo-variant-quantity").forEach((input) => {
        const currentQuantity = parseInt(input.value)
        const variant = this.shopifyProduct.variants.find((variant) => variant.id == parseInt(input.name))
        console.log("variant", variant)
        totalPriceCent += currentQuantity * variant.price
        totalQuantity += currentQuantity
      })

      if(FPD.instance) {
        totalPriceCent += FPD.instance.currentPrice * totalQuantity * 100
      }
      
      this.bulkOrderFooter.innerHTML = `<div class="fpd-bo-total-quantity">Total Quantity: ${totalQuantity}</div>`
      if(FPD.instance?.currentPrice > 0) {
        this.bulkOrderFooter.innerHTML += `<div class="fpd-bo-total-price">Extra per item: ${formatMoney(FPD.instance.currentPrice * 100, FPD.money_format)}</div>`
      }
      this.bulkOrderFooter.innerHTML += `<div class="fpd-bo-total-price">Total: ${formatMoney(totalPriceCent, FPD.money_format)}</div>`
    }

    initAtcListener() {
      console.log("initAtcListener")
      this.bulkOrderAtc = this.container.querySelector("#fpd-bo-bulk-order-atc")
      this.bulkOrderAtc.addEventListener("click", (e) => {
        e.preventDefault()
        this.addBulkOrderToCart()
      })
    }

    async addBulkOrderToCart() {
      if(isFunction(this.atcCallback)) {
        this.additionalProperties = await this.atcCallback()
      }
      const atcItems = []
      this.container.querySelectorAll("form input.fpd-bo-variant-quantity").forEach((input) => {
        if(input.value == 0) return

        const item = {
          id: input.name,
          quantity: input.value,
          properties: this.additionalProperties
        }
        console.log('item', item)
        atcItems.push(item)
      })

      if(isEmpty(atcItems)) return
      
      fetch(window.Shopify.routes.root + 'cart/add.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ items: atcItems })
      })
      .then(response => {
        return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }

    updatePrice() {
      this.renderSummeryFooter()
    }

    connectedCallback() {
      this.innerHTML = `
        <form>
          <div id="fpd-bo-bulk-order-table">
          </div>
          <div id="fpd-bo-bulk-order-footer">
          </div>
          <button id="fpd-bo-bulk-order-atc" class="button" type="submit">Add to Cart</button>
        </form>
      `
    }

    static angleDownSvg = `
      <svg class="svg-angle-down" role="img" viewBox="0 0 256 512" width="8" style="fill: rgb(52, 61, 71);">
        <path d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z">
        </path>
      </svg>
    `
}

customElements.define('fpd-bulk-order-form', BulkOrderForm)